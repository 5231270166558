@import './common';
// @import '../../../assets/ts-scss-vars/device-width-spec.json';

$bodyBgColor:#F5F5F5;
$topbarSubmenuHoverBgColor:#f1f2f7;
$topbarMobileMenuBgColor:#ffffff;
$mobileBreakpoint:1024px;
$contentMobileMaskBgColor:#424242;

$stdMobile_max: 750px;
$stdTablet_min: $stdMobile_max + 1px;
$stdTablet_max: 1247px;
$stdDesktop_min: $stdTablet_max + 1px; // 1248px === 13inch screen

$drawsListItem_maxWidthSmall: 1200px;

$drawsListItem_minWidthMid: $drawsListItem_maxWidthSmall + 1px;
